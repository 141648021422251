<template>
    <div class="milovana">
        <h1><a href="https://milovana.com/webteases" target="_blank">Milowana Web teases</a></h1>
        <TagsPool :selected="selected" :tags="tagsForPool" @select="select" @unselect="unselect"></TagsPool>
        <WebTeases :teases="teasesForDisplay"></WebTeases>
    </div>
</template>

<script>
  import { getTags, getTeases } from '../api/milovana'
  import TagsPool from '../components/milovana/TagsPool'
  import WebTeases from '../components/milovana/WebTeases'

  export default {
    name: 'Milovana',
    components: {WebTeases, TagsPool},
    data () {
      return {
        tags: {},
        teases: [],
        selected: [],
        tagsForPool: [],
        ids: [],
      }
    },
    async created () {
      await getTeases().then(t => {
        this.teases = t
        for (const tease of this.teases) {
          this.ids.push(tease.tease[1])
        }
      })
      getTags().then(t => {
        this.tags = t
        this.tagsForPool = []
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.tags)) {
          if (this.ids.indexOf(key) === -1) {
            continue
          }
          for (const tag of value) {
            if (this.tagsForPool.indexOf(tag) === -1) {
              this.tagsForPool.push(tag)
            }
          }
        }
        this.tagsForPool.sort()
      })
    },
    computed: {
      teasesForDisplay () {
        const keys = []
        // eslint-disable-next-line no-unused-vars
        for (const [key, tags] of Object.entries(this.tags)) {
          if (this.selected.every(v => tags.includes(v))) {
            keys.push(key)
          }
        }

        return this.teases.filter(t => keys.indexOf(t['tease'][1]) !== -1)
      },
    },
    methods: {
      select (tag) {
        this.selected.push(tag)
      },
      unselect (tag) {
        this.selected = this.selected.filter(t => t !== tag)
      },

    },

  }
</script>

<style scoped>

</style>