<template>
    <transition-group class="teases" name="zoom" tag="div">
        <div :key="tease.tease[1]" class="tease" v-for="tease in teases">
            <a :href="`https://oeos.art/?id=${tease.tease[1]}`" target="_blank">
                <img :src="require(`/public/milovana/img/${tease.tease[1]}.jpg`)" alt="" class="tease-img">
                <div class="tease-title">
                    {{tease.tease[0]}}
                </div>
            </a>
            <div>
                <a :href="`https://milovana.com/webteases/?author=${tease.member[1]}`" class="tease-creator"
                   target="_blank">
                    {{tease.member[0]}}
                </a>
            </div>
        </div>
    </transition-group>
</template>

<script>
  export default {
    name: 'WebTeases',
    props: ['teases'],
  }
</script>

<style lang="scss" scoped>
    .teases {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .tease {
        transition: all .3s ease;
        margin: .5rem;
        min-height: 150px;
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            font-size: 12px;
            padding: .1rem 0;
        }

        &-creator {
            display: inline-block;
            font-size: 12px;
            margin-top: 3px;
            padding: 2px 4px;
            background-color: #fff;
            border-radius: 2px;
            border: 1px solid #222;
            color: #222;
            transition: background-color .1s;

            &:hover {
                background-color: #c0c0c0;
            }

        }

        &-img {
            max-width: 100%;
        }
    }

    .zoom {
        &-enter-active, &-leave-active {
            transition: all .3s ease;
        }

        &-enter-active {
            transform: scale(0);
        }

        &-enter-to {
            transform: scale(1);
        }

        &-leave-to {
            transform: scale(0);
        }
    }
</style>