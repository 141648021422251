<template>
    <div class="tags-pool">
        <div :class="{'tag-selected': selected.indexOf(tag) !== -1}" :key="tag"
             @click.prevent="toggle(tag,selected.indexOf(tag) !== -1)" class="tag" v-for="tag in tags">
            {{tag}}
        </div>
    </div>
</template>

<script>
  export default {
    name: 'TagsPool',
    props: ['tags', 'selected'],
    methods: {
      toggle (tag, selected) {
        this.$emit(selected ? 'unselect' : 'select', tag)
      }
    },
  }
</script>

<style lang="scss" scoped>
    .tags-pool {
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 960px;
    }

    .tag {
        font-size: 12px;
        background-color: #7fffd4;
        padding: 4px 8px;
        border: 1px solid #254a38;
        border-radius: 3px;
        margin: .2rem .1rem;
        transition: background-color .1s;
        color: #353535;
        cursor: pointer;

        &:hover, &:focus {
            background-color: #289582;
        }

        &-selected {
            background-color: #b96b59;
            border-color: #6f3737;

            &:hover, &:focus {
                background-color: #7a3d3d;
            }
        }
    }
</style>